import React, { Component } from 'react';
import uuid from 'uuid';
import parse from 'html-react-parser';
import { connect } from 'react-redux';
import { Tooltip } from 'react-bootstrap';
import { ToolTipController, Select } from 'react-tooltip-controller';

import './index.css';
import { getImage } from '../helpers/Common';
import Actions from './Actions';
import Attachment from './Attachment';
import { ProfileImage } from '../helpers/ImageMapper';
import { months } from '../constants/index';
import MyPopover from './Popover';
import MessageLoader from './MessageLoader';
import Checkmark from './Checkmark';

import seenIcon from '../public/icons/seen.svg';
import receivedIcon from '../public/icons/received.svg';
import sentIcon from '../public/icons/sent.svg';

import ProductUrlsCarouselView from './ProductUrlsCarousel';

import flatten from 'flat';
import MicrolinkCard from '@microlink/react';
import { attachment } from '../javascripts/models/Message';

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      show: false,
      id: this.props.id,
      showCheckMark: false
    };
    this.getMessageBody = this.getMessageBody.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  tooltip(id, tooltip, chat_parent) {
    return <Tooltip id={id}>{tooltip}</Tooltip>;
  }
  componentDidMount() {
    // document.addEventListener('click', e => {
    //   if (e.target.id !== this.state.id) {
    //     this.setState({
    //       show: false
    //     });
    //   }
    // });
    if (this.props.backgroundClass === 'message_type_note') {
      // debugger;
      let text = JSON.parse(JSON.stringify(this.props.message.message.message.text));
      if (this.props.message.message.message.taggedUsers) {
        const taggedUsers = this.props.message.message.message.taggedUsers;
        for (let i = 0; i < taggedUsers.length; i++) {
          const regex = new RegExp('@' + taggedUsers[i].name + '', 'g');
          text = text.replace(regex, '<a>@' + taggedUsers[i].name + '</a>');
        }
        // document.querySelector('.message_type_note').innerHTML = text ;
      }
      this.setState({
        text: parse(text)
      });
    } else if (this.props.messageObject.type === 'event') {
      this.setState({
        text: this.props.text
      });
    } else {
      this.setState({
        text: this.props.message.message.message.text
      });
    }
  }
  getPopoverContent(data) {
    let flatData = flatten(data);
    flatData = this.removeEmptyObject(flatData);
    let popoverContent = [];
    for (let i in flatData) {
      popoverContent.push(
        <p key={uuid()}>
          <strong>{i}</strong> : {flatData[i] || ''}
        </p>
      );
    }
    return popoverContent;
  }

  removeEmptyObject(data) {
    for (let key in data) {
      if (data[key] === null || data[key] === '') delete data[key];
      else if (data[key] !== null && typeof data[key] === 'object') {
        this.removeEmptyObject(data[key]);
        if (Object.keys(data[key]).length === 0) {
          delete data[key];
        }
      }
    }
    return data;
  }

  getValidDate = time_stamp => {
    if (!time_stamp) return;
    let date = new Date(time_stamp);
    if (date instanceof Date && !isNaN(date)) return date;
    return null;
  };
  getStatusIcon = message_status => {
    if (!message_status) return sentIcon;
    if (message_status === 'seen') return seenIcon;
    if (message_status === 'received') return receivedIcon;
  };
  getMessageBody(message, eventIndex, messageObject, bodyClass, backgroundClass, id) {
    if (messageObject.type === 'event') {
      return (
        <React.Fragment>
          <div className="event_item" onClick={this.handleClick} id={id}>
            {this.state.text}
          </div>

          <MyPopover
            show={this.state.show}
            title={message.message.message.events[eventIndex].name}
            handleClick={this.handleClick}
          >
            {this.getPopoverContent(message.message.message.events[eventIndex].data)}
          </MyPopover>
        </React.Fragment>
      );
    } else {
      let { text, LinkPreviews, urlMatches, shopifyProductUrls } = detectUrls(this.state.text);
      return (
        <React.Fragment>
          {urlMatches.length > 0 ? (
            <div
              className="preview-urls"
              style={
                backgroundClass === 'message_type_user'
                  ? { borderLeft: '2px solid #ccc' }
                  : { borderRight: '2px solid #ccc' }
              }
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: text
                }}
              />

              {LinkPreviews}
              {validateVideoLinks(urlMatches)}
              {shopifyProductUrls.length > 1 && (
                <ProductUrlsCarouselView shopifyProductUrls={shopifyProductUrls} />
              )}
            </div>
          ) : (
            <div id={id} className={bodyClass + ' ' + backgroundClass}>
              <span> {this.state.text}</span>
            </div>
          )}
        </React.Fragment>
      );
    }
    function validateVideoLinks(links) {
      let embedVideos = [];
      embedVideos =
        links &&
        links.length > 0 &&
        links.map((link, i) => {
          var youTubeVideoRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
          var vimeoVideoRegex = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
          if (link.match(youTubeVideoRegex)) {
            let videoId = link ? link.split('v=')[1] : '';
            return (
              <iframe
                key={i}
                title={`https://www.youtube.com/embed/${videoId}${i}`}
                width="300"
                height="200"
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
              ></iframe>
            );
          }
          if (link.match(vimeoVideoRegex)) {
            let regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
            let parseUrl = regExp.exec(link);
            let vimeoId = parseUrl[5];
            return (
              <iframe
                key={i}
                title={`https://player.vimeo.com/video/${vimeoId}${i}`}
                src={`https://player.vimeo.com/video/${vimeoId}`}
                width="300"
                height="200"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
              ></iframe>
            );
          }
        });

      return embedVideos;
    }
    function insertTextAtIndices(text, obj) {
      return text.replace(/./g, function(character, index) {
        return obj[index] ? obj[index] + character : character;
      });
    }
    function detectUrls(text) {
      let messageText = String(text);
      let urlMatches = messageText ? messageText.match(/\b(http|https)?:\/\/\S+/gi) : [];
      let shopifyProductUrls = [];
      urlMatches &&
        urlMatches.length > 0 &&
        urlMatches.forEach(link => {
          const startIndex = messageText.indexOf(link);
          const endIndex = startIndex + link.length;
          if (link.match(/[^.\s]+\.myshopify\.com/)) {
            shopifyProductUrls.push(link);
          }
          messageText = insertTextAtIndices(messageText, {
            [startIndex]: `<a href="${link}" target="_blank" rel="noopener noreferrer" className="embedded-link">`,
            [endIndex]: '</a>'
          });
        });
      const LinkPreviews =
        urlMatches &&
        urlMatches.length > 0 &&
        urlMatches.map((link, i) => (
          <MicrolinkCard key={i + new Date().getMilliseconds()} url={link} />
        ));
      return {
        text: messageText,
        LinkPreviews: LinkPreviews || [],
        urlMatches: urlMatches || [],
        shopifyProductUrls: shopifyProductUrls
      };
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.pendingMessagesId.indexOf(this.props.messageId) >= 0 &&
      this.props.pendingMessagesId.indexOf(this.props.messageId) < 0
    ) {
      this.setState({ showCheckMark: true });
    }
  }

  handleClick() {
    this.setState({
      show: !this.state.show
    });
  }

  getTime(message) {
    const time = new Date(message.createdAt.seconds * 1000);
    const day = time.getDate();
    const month = time.getMonth();
    const year = time.getFullYear();
    const now = new Date();
    const amPm = time.getHours() > 12 ? 'PM' : 'AM';
    const hours =
      time.getHours() > 12
        ? time.getHours() - 12
        : time.getHours() === 0
        ? time.getHours() + 12
        : time.getHours();
    const minutes = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
    const today_date = now.getDate();
    const today_month = now.getMonth();
    const today_year = now.getFullYear();
    if (day === today_date && month === today_month && year === today_year) {
      return hours + ':' + minutes + ' ' + amPm;
    } else if (year === today_year) {
      let time_string = day + ' ' + months[month] + ' ' + hours + ':' + minutes + ' ' + amPm;
      return time_string
        .split(' ')
        .slice(2, 4)
        .join(' ');
    } else {
      let time_string =
        day + ' ' + months[month] + ' ' + year + ' ' + hours + ':' + minutes + ' ' + amPm;
      return time_string
        .split(' ')
        .slice(2, 4)
        .join(' ');
    }
  }

  render() {
    let {
      message,
      id,
      bodyClass,
      eventIndex,
      backgroundClass,
      chat_parent,
      display_image,
      messageObject,
      conversation
    } = this.props;
    let img;
    if (display_image) {
      if (chat_parent === 'chat_left_parent') {
        if (
          display_image === '/user.svg' &&
          messageObject.type !== 'event' &&
          conversation !== null &&
          conversation !== undefined &&
          conversation.data &&
          conversation.data.sender.uniqueIdentifier
        ) {
          let profile = getImage(conversation.data.sender.uniqueIdentifier, 'max');
          img = (
            <div
              style={{
                width: '50px',
                height: '50px',
                background: `url(${require('../public/icons/' + profile.src)}) ${profile.position}`,
                borderRadius: '50%',
                backgroundSize: profile.size
              }}
            ></div>
          );
        } else if (display_image.includes('http') && messageObject.type !== 'event') {
          img = (
            <img
              src={display_image}
              style={{ borderRadius: '50%', width: '32px', height: '32px' }}
              alt="user"
            />
          );
        }
      } else {
        // if (chat_parent === 'chat_right_parent') debugger;
        if (display_image === 'user') {
          img = (
            <img
              src="/user.svg"
              style={{ borderRadius: '50%', width: '50px', height: '50px' }}
              alt="user"
            />
          );
        } else {
          img = <ProfileImage name={display_image} className="messageUserImage" />;
        }
      }
    } else {
      img = '';
    }
    let message_status;
    let { messages_seen_at, messages_received_at } = this.props;
    let message_time = new Date(this.props.message.createdAt.seconds * 1000);
    if (messages_seen_at) {
      let date = this.getValidDate(messages_seen_at);
      if (date && message_time <= date) message_status = 'seen';
    }
    if (!message_status && messages_received_at) {
      let date = this.getValidDate(messages_received_at);
      if (date && message_time <= date) message_status = 'received';
    }
    return (
      <React.Fragment>
        <div className="message">
          {/* <ToolTipController
            detect="hover"
            offsetX={chat_parent === 'chat_left_parent' ? -129 : 497}
            offsetY={-33}
          > */}
          {/* <Select> */}
          <div
            className={
              chat_parent === 'chat_left_parent' && messageObject.type === 'event'
                ? 'event_message chat_left_parent'
                : chat_parent
            }
          >
            {messageObject.type !== 'event' ? <div className={'chat-img'}>{img}</div> : null}
            {this.getMessageBody(
              message,
              eventIndex,
              messageObject,
              bodyClass,
              backgroundClass,
              id
            )}
          </div>

          {/* </Select> */}

          <div
            className={`custom-tooltip ${
              chat_parent === 'chat_left_parent'
                ? messageObject.type === 'event'
                  ? 'custom-tooltip-arrow-right'
                  : 'custom-tooltip-arrow-right-cp'
                : 'custom-tooltip-arrow-left'
            }`}
          >
            <span className="message-time-text">{this.getTime(message)}</span>
            {this.props.pendingMessagesId.indexOf(this.props.messageId) >= 0 ? (
              <div className={'message-delivery-status'}>
                <MessageLoader />
              </div>
            ) : (
              <>
                {backgroundClass === 'message_type_reply' && (
                  <span className={'message-delivery-status'}>
                    <img
                      style={{ width: '20px' }}
                      src={this.getStatusIcon(message_status)}
                      alt=""
                    />
                  </span>
                )}
                {backgroundClass === 'message_type_note' && (
                  <span className={'message-delivery-status'}>
                    <img style={{ width: '20px' }} src={sentIcon} alt="" />
                  </span>
                )}
              </>
            )}
          </div>
          {chat_parent === 'chat_right_parent' && messageObject.sender.name ? (
            <div className="sent-by">
              <span className="sent-by-hint">Sent by</span> {messageObject.sender.name}
            </div>
          ) : null}
          <div className="">
            <Actions actions={message.message.message.actions} />
          </div>
          <div
            className={
              chat_parent === 'chat_left_parent' ? 'left-img-attachment' : 'img-attachment'
            }
          >
            <Attachment message={message.message.message} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    conversation: state.activeConversation.conversation,
    pendingMessagesId: state.message.pendingMessagesId
  };
};

export default connect(mapStateToProps, null)(Chat);
